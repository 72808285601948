@import "~antd/lib/style/index";
@import '~antd/dist/antd.css';
.font{
  font-family: 'Plus Jakarta Sans';
}
select option {
  margin: 40px;
  background-color: transparent !important;

  text-shadow: 0 1px 0 rgba(0, 0, 0, 0.4);
}

.container {
  width: 100%;
  display: flex;
  align-self: center;
  margin: auto;
}

.backimg {
  position: 0 -300px;
  filter: brightness(50%);
  width: -webkit-fill-available;
  height: 300px;
}

.image_title {
  font-weight: 700;
  font-size: 40px;
}

.image_content {
  font-weight: 400;
  font-size: 16px;
  color: rgba(255, 255, 255, 0.76);
}

.App-logo {
  height: 2rem;
  pointer-events: none;
}


h1 {
  color: white;
}

a {
  text-decoration: none !important;
}


.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.ant-collapse > .ant-collapse-item > .ant-collapse-header {
  padding: 10px 0px;
  background-color: white;
}
.ant-collapse > .ant-collapse-item-active > .ant-collapse-header {
  padding-bottom: 10px;
  background-color: white;
}
.ant-collapse > .ant-collapse-item-active > .ant-collapse-content-active > .ant-collapse-content-box {
  padding-top: 0px;
  padding-bottom: 0px;
}

.checkout .ant-collapse > .ant-collapse-item > .ant-collapse-header {
  background-color: transparent;
}
.pinpoint-modal.ant-modal .ant-modal-content {
  border-radius: 16px;
}

.pinpoint-modal.ant-modal .ant-modal-content .ant-modal-header{
  border-radius: 16px;
}

.header-p-0.ant-collapse > .ant-collapse-item > .ant-collapse-header {
  padding: 0px;
}

#pricing-tab .ant-switch-handle::before {
  background-color: #F6CC08;
  height: 30px;
  width: 30px;
  border-radius: 100%;
  top: -1px;
  left: 1px;
}

#pricing-tab .ant-switch-checked .ant-switch-handle {
  left: calc(100% - 34px);
}

.ant-modal.watch-video-modal {
  height: 80vh;
  width: 80vw !important;
}

.ant-modal.watch-video-modal .ant-modal-body {
  height: 80vh;
 }